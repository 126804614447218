main {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

main>* {
    width: 100%;
    min-height: 100%;
    /* height: 100%; */
}