.header {
    height: 4rem;
}

.topNav {
    flex-grow: 1;
}

.navActive {
    background-color: rgba(0, 0, 0, .4) !important;
}

.logoImg {
    height: 55px;
}

.logoImgMobile {
    height: 40px;
    flex-grow: 1;
    display: flex;
}

.avatar {}